import React, {useState, useEffect, useMemo} from "react"
import { graphql } from "gatsby"
import {Helmet} from "react-helmet"
import {FaAngleLeft, FaAngleRight, FaTimes, FaCircle} from "react-icons/fa"

const Events = ({data}) => {
    const [tags,events] = useMemo(()=>{
        const events = [];
        const tagSet = new Set(['all']);
        data.allWpEvent.edges.forEach(edge=>{
            if(edge.node.eventinfo.tag){
                tagSet.add(edge.node.eventinfo.tag);
            }else{
                edge.node.eventinfo.tag = 'Other';
            }
            events.push({
                title:edge.node.title,
                image:edge.node.eventinfo.images?edge.node.eventinfo.images.map(item=>item.sourceUrl):[],
                tag:edge.node.eventinfo.tag,
                content:edge.node.eventinfo.summary,
                times:edge.node.eventinfo.date,
                alt:''
            })
        });
        // 先删后加，保证Other在最后一位
        tagSet.delete('Other');
        tagSet.add('Other');
        return [Array.from(tagSet),events];
    },[]);
    const [currentTag, setCurrentTag] = useState("all")
    const [open, setIsOpen] = useState(false)
    const [selected, setSelected] = useState("")
    const [filteredTags, setFilterTags] = useState(events)
    const [carouselIndex, setCarouselIndex] = useState(0)
    useEffect(() => {
        document.body.style.overflow = (open) ? "hidden" : "auto"
    }, [open])
    useEffect(()=>{

    },[])
    useEffect(() => {
        setCarouselIndex(0)
    }, [selected])

    const openLightbox = (event, prev, next) => {
        setIsOpen(!open)
        setSelected({
            "event": event,
            "prev": prev,
            "next": next
        })
    }

    const filterTags = (tag) => {
        setCurrentTag(tag)
        setFilterTags(() => {
            return (tag === "all") ? events : events.filter(event => event.tag === tag)
        })
    }

    const clickLightbox = (e) => {
        if (!e.target.closest(".events-lightbox-box")) {
            setIsOpen(!open)
        }
    }

    const navigate = (eventNumber) => {
        if (eventNumber > events.length - 1 || eventNumber < 0) {
            return;
        }

        setSelected({
            "event": events[eventNumber],
            "prev": eventNumber - 1,
            "next": eventNumber + 1
        })
    }

    const carouselNext = () => {
        if (carouselIndex < selected.event.image.length-1) {
            setCarouselIndex(prevState => prevState + 1)
        }
    }

    const carouselPrev = () => {
        if (carouselIndex > 0) {
            setCarouselIndex(prevState => prevState - 1)
        }
    }

    return (
        <div className="trondao-events">
            <Helmet>
                <title>TRON Events | TRONDAO</title>
                <meta name="description"
                      content="What is happening in the world of TRON"/>
                <meta property="og:description" content="What is happening in the world of TRON"/>
                <meta property="og:title" content="TRON Events | TRONDAO"/>
            </Helmet>
            <div className="events">
                <div className="events-main">
                    <div
                        className="events-main-title d-flex align-items-center justify-content-center col-lg-8 mx-auto">
                        <h1 className="text-uppercase text-center">What is happening in the world of TRON</h1>
                    </div>
                    <div style={{margin:"0 auto 40px",width:"90vw",maxWidth:"800px"}}>
                        <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FNew_York&bgcolor=%23ffffff&src=Y184Y2YyZmVmOGE1YmM4NzFmYjQ3OGZlY2RjMmVmMzM2NTdmMzg1MWNmMWFmZDE1ZjYxZjYzODZiNjIzNDdhNDM3QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%233F51B5" style={{border:"1px solid #777",width:"100%"}} height="600" frameborder="0" scrolling="no"></iframe>
                    </div>

                    <div
                        className="events-main-tags flex-wrap col-11 col-sm-8 col-md-10 col-lg-8 col-xl-7 d-flex mx-auto">
                        {
                            tags.map((tag, i) => {
                                return (
                                    <button key={i} type="button" className={tag === currentTag ? 'active' : ''}
                                            onClick={() => filterTags(tag)}>{tag}</button>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="events-body col-11 col-lg-10 mx-auto">
                    {
                        filteredTags.map((event, key) => {
                            return (
                                <div key={key} onClick={() => openLightbox(event, key - 1, key + 1)}
                                     onKeyDown={() => openLightbox(event)} role="presentation"
                                     className="events-body-card d-flex flex-column">
                                    <div className="img-wrap">
                                        <img src={event.image[0]} alt={event.alt} className="img-fluid"/>
                                    </div>
                                    <div className="p-4 d-flex flex-column justify-content-between">
                                        <div>
                                            <h1>{event.title}</h1>
                                            <p>
                                                {event.content}
                                            </p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mt-4">
                                            <div className="events-body-card-date">
                                                {/* August 10, 2022 • NYC */}
                                                {event.times}
                                            </div>
                                            <div className="events-body-card-tag">
                                                {event.tag}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                {
                    open ?
                        <div onClick={(e) => clickLightbox(e)} onKeyDown={(e) => clickLightbox(e)}
                             className="events-lightbox" id="events-lightbox" role="presentation">
                            <div className="events-lightbox-box">
                                <div className="events-lightbox-box-slider">
                                    <button onClick={carouselPrev} className="events-lightbox-box-left-arrow mr-5">
                                        <FaAngleLeft size={20} alt="angle left icon"/>
                                    </button>
                                    <button onClick={carouselNext} className="events-lightbox-box-right-arrow">
                                        <FaAngleRight size={20} alt="angle right icon"/>
                                    </button>
                                    <button onClick={() => setIsOpen(!open)} className="events-lightbox-box-close">
                                        <FaTimes size={20} alt="close icon"/>
                                    </button>
                                    <div className="events-lightbox-box-slide"
                                         style={{transform: `translateX(-${carouselIndex * 100}%)`}}>
                                        {
                                            selected.event.image.map((img, i) => {
                                                return (
                                                    <img key={i} src={img} alt="sample images" className="img-fluid"/>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="events-lightbox-box-dots">
                                        {
                                            selected.event.image.map((_,i) => {
                                                return (
                                                    <FaCircle key={i} size={12} alt="dots"
                                                              style={{fill: (i === carouselIndex) ? "white" : ""}}/>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="p-3 px-lg-5 py-lg-4 d-flex flex-column justify-content-between">
                                    <div className="events-lightbox-details d-flex flex-column">
                                        <div>
                                            <h1>{selected.event.title}</h1>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="events-body-card-date mr-3">
                                                {/* August 10, 2022 • NYC */}
                                                {selected.event.times}
                                            </div>
                                            <div className="events-body-card-tag">
                                                {selected.event.tag}
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <p>
                                            {selected.event.content }
                                            </p>
                                        </div>
                                    </div>

                                    {/* <div className="d-flex flex-column mt-4">
                                        <div>
                                            <h6 className="text-uppercase">Videos</h6>
                                        </div>
                                        <div className="events-lightbox-cover d-flex">
                                            {
                                                coverImage.map((image, i) => {
                                                    return (
                                                        <img key={i} src={image} alt="video cover"/>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div> */}
                                </div>
                                <div className="events-lightbox-buttons d-flex">
                                    <button
                                        type="button"
                                        onClick={() => navigate(selected.prev)}
                                        className="events-lightbox-prev"
                                        disabled={selected.prev < 0 ? true : false}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => navigate(selected.next)}
                                        className="events-lightbox-next"
                                        disabled={selected.next > events.length - 1 ? true : false}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div> : ""
                }
            </div>
        </div>
    )
}

export default Events
export const query = graphql`
    query event {
        allWpEvent {
            edges {
                node {
                    uri
                    title
                    slug
                    eventinfo {
                        date
                        introduction
                        location
                        summary
                        images {
                            sourceUrl
                        }
                        tag
                    }
                }
            }
        }
    }
`